<template>
  <div class="row win">
    <!-- 左 -->
    <div class="column left">
      <div class="column" style="height:100%;">
        <img src="../assets/images/login/z1.png" class="z1" />
        <div class="row">
          <img src="../assets/images/login/z2.png" class="z2" />
          <img src="../assets/images/login/z3.png" class="z3" />
          <div class="column left-title">
            <label class="t1">/使命</label>
            <label class="t2">探索人类掌握一门外语的极限。</label>
          </div>
        </div>
      </div>
    </div>
    <!-- 右 -->
    <div class="column right">
      <div class="column login">
        <div class="row title">
          <img src="../assets/images/login/logo.png" />
          <label>好英语,练出来!</label>
        </div>
        <div class="colnum title-1">
          <label v-if="loginType==1">Welcom to HuiLian</label>
          <img v-if="loginType==1" src="../assets/images/login/paishou.png" />
          <label v-if="loginType==2">账号登录</label>
          <img v-if="loginType==2" src="../assets/images/login/weixiao.png" />
          <label v-if="loginType==3">忘记密码</label>
        </div>
        <!-- 微信 -->
        <div v-if="loginType==1" class="colnum weChat">
          <div class="title-wechat">
            请使用手机微信扫码
            <img src="../assets/images/login/yougang.png" />
          </div>
          <div class="colnum state">
            <img src="../assets/images/login/shouji.png" />
          </div>
          <!-- 二维码 -->
          <div class="qr-code">
            <wxlogin
              :appid="wxlogin.appid"
              :scope="wxlogin.scope"
              :redirect_uri="wxlogin.redirect_uri"
              :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCiBkaXNwbGF5OiBub25lOwoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgogZGlzcGxheTogbm9uZTsKCn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewoKIGJvcmRlcjogbm9uZTsKCiB3aWR0aDogMjAwcHg7CgogaGVpZ2h0OiAyMDBweDsKCn0KCi5pbXBvd2VyQm94IC5zdGF0dXN7CgogZGlzcGxheTogbm9uZSAhaW1wb3J0YW50OwoKfQ=='"
            />
          </div>
          <!-- 账号登录切换 -->
          <div class="colnum account-switch" @click="loginType=2">
            <label style="cursor:pointer;">账号登陆</label>
            <img src="../assets/images/login/yousanjiao.png" />
          </div>
        </div>
        <!-- 账号密码登录 -->
        <div v-if="loginType==2" class="colnum account">
          <div class="row input">
            <el-input v-model="account" size="medium" placeholder="请输入账号">
              <template slot="prepend">
                <i class="el-icon-user"></i>
              </template>
            </el-input>
          </div>
          <div class="row input">
            <el-input v-model="pass" size="medium" placeholder="请输入密码" show-password>
              <template slot="prepend">
                <i class="el-icon-lock"></i>
              </template>
            </el-input>
          </div>
          <div class="row input">
            <el-checkbox v-model="savePass" class="savePass">自动登录</el-checkbox>
            <el-link type="primary" class="forgetPass" @click="loginType=3">忘记密码</el-link>
          </div>
          <div class="colnum but">
            <el-button type="primary" class="login-but" size="medium" @click="onLogin()">登 录</el-button>
            <div class="or">
              <img src="../assets/images/login/or.png" />
            </div>
            <el-button type="success" class="login-but" size="medium" @click="loginType=1">
              <img src="../assets/images/login/wx.png" style="width: 14px;" />
              微 信 登 陆
            </el-button>
          </div>
        </div>
        <!-- 找回密码 -->
        <div v-if="loginType==3" class="colnum retrieve">
          <div class="row input">
            <el-input v-model="account" size="medium" placeholder="请输入手机号">
              <template slot="prepend">
                <i class="el-icon-user"></i>
              </template>
            </el-input>
          </div>
          <div class="row input">
            <el-input v-model="pass" size="medium" placeholder="请输入新密码" show-password>
              <template slot="prepend">
                <i class="el-icon-lock"></i>
              </template>
            </el-input>
          </div>
          <div class="row input">
            <el-input
              v-model="imgCode"
              size="medium"
              placeholder="右侧图形验证码"
              suffix-icon="el-icon-eleme"
            >
              <div
                slot="append"
                style="width: 70px;display: flex;justify-content: center;align-items: center;"
              >
                <el-image
                  fit="cover"
                  style="cursor:pointer;"
                  :src="imgCodeUrl"
                  @click="onRefreshImg()"
                />
              </div>
            </el-input>
          </div>
          <div class="row input">
            <el-input
              v-model="smsCode"
              size="medium"
              placeholder="手机短信验证码"
              suffix-icon="el-icon-chat-line-square"
            >
              <div
                slot="append"
                style="width: 70px;display: flex;justify-content: center;align-items: center;"
              >
                <label
                  slot="append"
                  style="font-size: 12px;color: #B62B1A;cursor:pointer;"
                  @click="onSendSms()"
                >发送验证码</label>
              </div>
            </el-input>
          </div>
          <el-button type="primary" class="login-but" size="medium" @click="onModif()">确 认 修 改</el-button>
        </div>
      </div>
    </div>
    <div class="bott">
      <span>英语习得教育 © 2021 版权所有</span>
      <a
        target="_blank"
        href="https://beian.miit.gov.cn/"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
      >
        <img
          src="../icons/record.png"
          style=" float: left; width: 17px;height: 17px;margin-right: 3px;"
        />
        蜀ICP备2021011205号-3
      </a>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import wxlogin from 'vue-wxlogin'
import { uuid } from 'vue-uuid'
import md5 from 'js-md5'
import { smsCode, weChatVerify } from '@/apis/sys' // weChatLogin
import { login, forgotPassword, bindingWeChat } from '@/apis/user'
export default {
  name: 'Login',
  components: { wxlogin },
  props: {},
  data() {
    return {
      isLogin: false,
      loginType: 1,
      wxlogin: {
        appid: 'wx7ab9e59225d15d4a',
        scope: 'snsapi_login',
        redirect_uri: 'https://huilianyingyu.com/login?isbinding=False'
      }, // 微信登录信息
      account: '', // 账号
      pass: '', // 密码
      imgCodeUrl: '', // 图形验证码地址
      savePass: true, // 记住密码
      imgUuid: '', // 图形验证码uuid
      imgCode: '', // 图形验证码Code
      smsCode: '' // 短信验证码
    }
  },
  computed: {},
  watch: {},
  created() {
    var that = this
    that.imgUuid = uuid.v1()
    that.imgCodeUrl =
      Vue.$conf.service[Vue.$conf.environment] +
      'Sys/ImgCode?guid=' +
      that.imgUuid

    // 非绑定方式 微信登录
    var isbinding = this.$route.query.isbinding
    // 有微信登录/微信绑定 参数时
    if (isbinding !== undefined) {
      var code = this.$route.query.code
      if (isbinding === 'False') {
        if (code) {
          weChatVerify({ code: code }).then(res => {
            if (res.code === 200) {
              this.setUserInfo(res.data)
              this.$router.push({
                path: '/index'
              })
            }
          })
          return
        }
      } else {
        if (code) {
          bindingWeChat({ code: code }).then(res => {
            if (res.code === 200) {
              var userInfo = JSON.parse(localStorage.getItem('user'))
              userInfo.isWeChat = '已绑定'
              localStorage.setItem('user', JSON.stringify(userInfo))
              Vue.$setUserInfo()
              return
            }
          })
        }
      }
    }
  },
  mounted() {},
  methods: {
    // 登录
    onLogin() {
      login({
        Tele: this.account,
        LoginPass: md5(this.pass)
      }).then(res => {
        if (res.code === 200) {
          this.isLogin = false
          localStorage.setItem('savePass', this.savePass)
          this.setUserInfo(res.data)
          if (this.savePass) {
            localStorage.setItem('Tele', this.account)
            localStorage.setItem('LoginPass', md5(this.pass))
          } else {
            localStorage.removeItem('Tele')
            localStorage.removeItem('LoginPass')
          }
          this.$router.push({
            name: 'Index'
          })
        }
      })
    },
    // 记录用户数据
    setUserInfo(userInfo) {
      localStorage.setItem('user', JSON.stringify(userInfo))
      if (userInfo.grade === 1.1) {
        localStorage.setItem('showZh', true)
      }
    },
    // 微信登录
    onWeChatLogin() {
      this.isWxLogin = true
      // weChatLogin().then(res => {
      //   window.location.href = res.data
      // })
    },
    // 账号登录
    onAccountLogin() {
      this.isWxLogin = false
    },
    // 忘记密码切换
    onForgetPass() {
      this.pass = ''
      this.smsCode = ''
      this.isForgetPass = !this.isForgetPass
    },
    // 刷新验证码
    onRefreshImg() {
      this.imgUuid = uuid.v1()
      this.imgCodeUrl =
        Vue.$conf.service[Vue.$conf.environment] +
        'Sys/ImgCode?guid=' +
        this.imgUuid
    },
    // 发送验证码
    onSendSms() {
      var that = this
      smsCode({
        guid: this.imgUuid,
        imgCode: this.imgCode,
        tele: this.account
      }).then(res => {
        that.$notify({
          title: '成功',
          message: '验证码发送成功',
          type: 'success'
        })
      })
    },
    // 修改密码
    onModif() {
      var that = this
      forgotPassword({
        tele: this.account,
        loginPass: md5(this.pass),
        smsCode: this.smsCode
      }).then(res => {
        that.$notify({
          title: '成功',
          message: '密码修改成功',
          type: 'success'
        })
        this.isForgetPass = !this.isForgetPass
        this.pass = ''
        this.smsCode = ''
        this.loginType = 2
      })
    }
  }
}
</script>
<style lang='less' scoped>
//变量定义
@primary-clor: #6a6aee;
@padding-horizontal: 20px;
@padding-vertical: 10px;
@list-operation-height: 50px;
@tabs-top-height: 40px;
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.win {
  width: 100%;
  height: 100%;
}

.left {
  width: 70%;
  height: 100%;
  .z1 {
    object-fit: cover;
    width: 100%;
    height: calc(100% - 200px);
  }
  .z2 {
    object-fit: cover;
    height: 200px;
    width: calc(100% - 200px);
  }
  .z3 {
    object-fit: cover;
    width: 200px;
  }
  .left-title {
    color: white;
    position: absolute;
    bottom: 40px;
    left: 40px;
    .t1 {
      color: #f4d5d2;
      font-size: 34px;
    }
    .t2 {
      color: #ffffff;
      font-size: 20px;
      margin-top: 20px;
    }
  }
}
.right {
  width: 30%;
  // 左右上下居中
  display: flex;
  justify-content: center;
  align-items: center;
  .login {
    height: 600px;
    .title {
      display: flex;
      // justify-content: center;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
      label {
        color: #b62b1a;
        font-size: 14px;
        margin-left: 5px;
      }
    }
    .title-1 {
      display: flex;
      align-items: center;
      margin-top: 10px;
      label {
        font-size: 24px;
        font-weight: bold;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
    // 微信登录
    .weChat {
      background-image: url(../assets/images/login/y2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 400px;
      width: 300px;
      position: relative;
      margin-top: 30px;
      .title-wechat {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        color: #ffffff;
        font-size: 12px;
        position: relative;
        img {
          position: absolute;
          top: 8px;
          left: 31px;
          width: 230px;
        }
      }
      .state {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        img {
          width: 18px;
          height: 22px;
        }
      }
      .qr-code {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .account-switch {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 30px;
        font-size: 12px;
        color: #333333;
        img {
          width: 7px;
          height: 8px;
          margin-left: 5px;
        }
      }
    }
    //账号登录
    .account {
      .input {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        position: relative;
      }
      .savePass {
        position: absolute;
        left: 0px;
      }
      .forgetPass {
        position: absolute;
        right: 0px;
      }
      .but {
        width: 300px;
        .login-but {
          margin-top: 30px;
          width: 300px;
        }
        .or {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          img {
            width: 240px;
          }
        }
      }
    }
    // 忘记密码
    .retrieve {
      .input {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        position: relative;
      }
      .login-but {
        margin-top: 30px;
        width: 300px;
      }
    }
  }
}

.bott {
  position: fixed;
  height: 50px;
  width: 100%;
  background: #b62b1a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  bottom: 0px;
  left: 0px;
  font-size: 12px;
  z-index: 100000;
}
.bott a {
  color: white;
  margin-left: 10px;
}
</style>
